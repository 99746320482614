<template>
  <Base titleIcon="el-icon-s-claim">
    <el-space wrap style="margin-bottom: 12px">
      <el-radio-group v-model="filter.city">
        <el-radio-button :label="null">全部</el-radio-button>
        <el-radio-button v-for="city in cityDic" :label="city" :key="city">{{
          city
        }}</el-radio-button>
      </el-radio-group>
    </el-space>
    <el-table :data="fundFilter" v-loading="isLoading" style="width:500px;margin:auto;">
      <el-table-column
        label="资金安排年度"
        prop="资金安排年度"
      ></el-table-column>
      <el-table-column label="安排金额（万元）" prop="安排金额（万元）">
      </el-table-column>
    </el-table>
  </Base>
</template>

<script lang="ts">
import Base from "@/views/layout/Base.vue";
import { fund, cityDic } from "./data.js";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";

export default defineComponent({
  components: {
    Base,
  },
  setup() {
    const isLoading = ref(false);
    const fundFilter = ref();
    const groupSum = (obj) =>
      obj.reduce(
        (
          groups,
          { 市级: city, 资金安排年度: year, "安排金额（万元）": amount }
        ) => {
          var groupFound = groups.find(
            (arrItem) => year === arrItem["资金安排年度"]
          );
          if (groupFound) {
            if (typeof groupFound["安排金额（万元）"] != "number")
              groupFound["安排金额（万元）"] = parseFloat(
                groupFound["安排金额（万元）"]
              );
            groupFound["安排金额（万元）"] += parseFloat(amount);
          } else {
            //不要直接在原来的对象修改，新建对象
            var newGroup = {
              资金安排年度: year,
              "安排金额（万元）": parseFloat(amount),
            };
            groups.push(newGroup);
          }
          return groups;
        },
        []
      );

    const search = () => {
      isLoading.value = true;
      setTimeout(() => {
        fundFilter.value = groupSum(
          fund.filter((p) => filter.city == null || filter.city == p.市级)
        );
        isLoading.value = false;
      }, 200);
    };
    onMounted(() => {
      search();
      watch(filter, () => {
        search();
      });
    });

    const filter = reactive({
      city: null,
    });
    return {
      isLoading,
      cityDic,
      filter,
      fundFilter,
    };
  },
});
</script>

<style scoped>
</style>